<template>
  <div class="col-12 g c col-lg-4">
    <br><br>
    <div class="card card-body">
        <img :src="require('@/assets/images/banner.png')" style="width: 50%; margin: 0px auto; padding: 20px" alt="">
        <div class="alert alert-danger text-center g" v-if="error">
            {{ error }}
        </div>
        <div class="form-group g">
          <h5 for="">الاسم</h5>
          <input type="text"
            class="form-control" v-model="name">
        </div>
        <div class="form-group g">
          <h5 for="">الجوال</h5>
          <input type="text"
            class="form-control" v-model="phone">
        </div>
        <div class="form-group g">
          <h5 for="">كلمة المرور</h5>
          <input type="password"
            class="form-control" v-model="password">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="login()">
                 انشاء الحساب
            </button>
        </div>
        <br>
        <span>
             لديك حساب؟
            <a href="javascript:;" @click="$router.push('/login')">
                <i class="fa fa-sign-in"></i>
                تسجيل الدخول
            </a>
        </span>
    </div>
  </div>
</template>

<script>
export default {
    created(){

    },
    data(){
        return {
            phone: "",
            password: "",
            name: "",
            error: null
        }
    },
    methods: {
        login(){
            var g = this;
            g.error = null;
            $.post(api + '/user/auth/register', {
                phone: g.phone,
                password: g.password,
                name: g.name
            }).then(function(r){
                if(r.status != 100){
                    g.error = r.response
                }else{
                    localStorage.setItem("user", JSON.stringify(r.response))
                    window.location = '/'
                }
            }).fail(function(){
                g.error = "حدث مشكلة في الاتصال"
            })
        }
    }
}
</script>

<style>

</style>